.App {
  text-align: center;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 1px;
  background-color: white;
}

.App-header {
  background-color: #851b1b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-main {
  background-color: #851b1b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
